import React from 'react'
import cx from 'classnames'

import { Avatar, Text, TextSize } from 'components/dataDisplay'

import s from './AvatarHorizontallyBlock.scss'


export type AvatarHorizontallyBlockProps = {
  className?: string
  userName: string
  position?: string
  image?: string
  size: 32 | 40 | 56
  margin?: IndentSize
  userNameTextSize?: TextSize
  positionTextSize?: TextSize
  style?: 'dark' | 'light'
  withAvatar?: boolean
  withFullscreen?: boolean
  handleClick?: () => void
}

const AvatarHorizontallyBlock: React.FC<AvatarHorizontallyBlockProps> = ({
  className,
  userName,
  position,
  image,
  size,
  handleClick,
  margin = 8,
  userNameTextSize,
  positionTextSize,
  withAvatar = true,
  withFullscreen,
  style,
}) => {

  return (
    <div
      className={cx(className, 'flex', {
        [s.clickable]: handleClick,
      })}
      onClick={handleClick}
    >
      {
        withAvatar && (
          <Avatar
            className={cx({
              [`mr-${margin}px`]: margin,
            })}
            userName={userName}
            image={image}
            size={size}
            withFullscreen={withFullscreen}
            style={style}
          />
        )
      }
      <div className="flex flex-col justify-center">
        {
          size === 32 ? (
            <Text
              message={userName}
              size={userNameTextSize ? userNameTextSize : 's13-r'}
              color="interstellar"
            />
          ) : (
            position ? (
              <>
                <Text
                  className={cx(s.username40, 'text-left')}
                  message={userName}
                  size={userNameTextSize ? userNameTextSize : 's13-b'}
                  color="titanic"
                />
                <Text
                  className={cx(s.position40, 'opacity-72 text-left')}
                  message={position}
                  size={positionTextSize ? positionTextSize : 's10'}
                  color="titanic"
                />
              </>
            ) : (
              <Text
                className={s.username40}
                message={userName}
                size={userNameTextSize ? userNameTextSize : 'h16-20'}
                color="titanic"
              />
            )
          )
        }
      </div>
    </div>
  )
}

export default AvatarHorizontallyBlock