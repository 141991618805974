import React from 'react'
import cx from 'classnames'

import { MP4Player, StreamPlayer, YoutubePlayer } from './components'

import s from './Video.scss'


export type VideoProps = {
  className?: string
  title?: string
  type: 'mp4' | 'youtube' | 'stream'
  url: string
  preview?: string
  autoplay?: boolean
  controls?: boolean
  onPlay?: () => void
}

const Video: React.FC<VideoProps> = (props) => {
  const { className, type, ...rest } = props

  const Component = ({
    'mp4': MP4Player,
    'youtube': YoutubePlayer,
    'stream': StreamPlayer,
  }[type])

  if (Component) {
    return (
      <div className={cx(s.container, className)}>
        {
          React.createElement(Component, {
            ...rest,
          })
        }
      </div>
    )
  }

  console.error('Video type is not recognized')

  return null
}


export default Video
