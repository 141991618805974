import React, { forwardRef, useEffect, useCallback, useState, CSSProperties } from 'react'
import cx from 'classnames'
import logger from 'logger'

import { isInlineSvgSupported, loadSvg } from './util'


type SvgData = {
  body: string
  svgAttributes: Record<string, string>
}

type InlineSvgProps = {
  className?: string
  src: string
  preload?: string
  aspect?: number
  width?: string | number
  height?: string | number
  ariaDescribedby?: string
  style?: CSSProperties
}

const InlineSvg = forwardRef<SVGSVGElement, InlineSvgProps>((props, ref) => {
  const { className, src, preload, aspect = 1, ariaDescribedby, width, height, style } = props

  const [ svgData, setSvgData ] = useState<SvgData>(null)

  const handleLoad = useCallback((src) => {
    let isCancelled = false

    if (preload) {
      loadSvg(preload)
    }

    loadSvg(src)
      .then(
        (svgData) => {
          if (!isCancelled) {
            setSvgData(svgData)
          }
        },
        (error) => logger.warn(error)
      )

    // disable calling useState on unmount
    return () => {
      isCancelled = true
    }
  }, [])

  useEffect(() => {
    if (!isInlineSvgSupported) {
      logger.warn('InlineSVG: Unsupported browser')
      return
    }

    if (src) {
      return handleLoad(src)
    }

    logger.error('InlineSVG: Empty src')
  }, [ src, handleLoad ])

  const { body, svgAttributes } = svgData || {}

  if (body && svgAttributes) {
    return React.createElement('svg', {
      className: cx('inline-block', className),
      draggable: false,
      ...svgAttributes,
      width: width || svgAttributes.width,
      height: height || svgAttributes.height,
      ariaDescribedby,
      dangerouslySetInnerHTML: { __html: body },
    })
  }

  return (
    <svg
      ref={ref}
      role="img"
      width={width}
      height={height}
      className={cx('inline-block', className)}
      viewBox={`0 0 ${aspect} 1`}
      aria-describedby={ariaDescribedby}
      style={style}
    />
  )
})


export default React.memo(InlineSvg)
