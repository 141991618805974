import Hls from 'hls.js'
import cx from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'

import loaderImage from 'components/dataDisplay/Image/images/spinner.svg'

import s from './StreamPlayer.scss'


type StreamPlayerProps = {
  title?: string
  url: string
  preview?: string
  autoplay?: boolean
  controls?: boolean
  onPlay?: () => void
}

const StreamPlayer: React.FC<StreamPlayerProps> = (props) => {
  const { title, url, preview, autoplay, controls, onPlay } = props

  const hlsRef = useRef<Hls>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ isFullscreen, setIsFullscreen ] = useState<boolean>(false)

  useEffect(() => {
    hlsRef.current = new Hls()
    hlsRef.current.on(Hls.Events.FRAG_LOADED, () => setIsLoading(false))

    return () => {
      hlsRef.current.detachMedia()
      hlsRef.current.destroy()
    }
  }, [])

  const play = useCallback(() => {
    setIsLoading(true)
    hlsRef.current.loadSource(url)
    hlsRef.current.attachMedia(videoRef.current)
  }, [ url ])

  useEffect(() => {
    if (autoplay) {
      play()
    }
  }, [ autoplay, play ])

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) {
      document.exitFullscreen()
    } else {
      videoRef.current.parentElement.requestFullscreen()
        .then(() => {
          setIsFullscreen(true)
        })
        .catch((error) => console.error('Error while trying to go video fullscreen:', error))
    }
  }, [ isFullscreen ])

  useEffect(() => {
    const listenEscapePress = (event) => {
      if (event.key === 'Escape' && isFullscreen) {
        toggleFullscreen()
      }
    }

    const handleDocumentFullscreenChange = (event) => {
      const isDocumentFullscreen = Boolean(document.fullscreenElement)
      if (!isDocumentFullscreen) {
        setIsFullscreen(false)
      }
    }

    document.addEventListener('keydown', listenEscapePress)
    document.addEventListener('fullscreenchange', handleDocumentFullscreenChange) // we need this
    // listener in case of exiting fullscreen by pressing 'Escape' key, in this situation browsers
    // do not fire an 'keypress' event so we listen to 'fullscreenchange' event

    return () => {
      document.removeEventListener('keydown', listenEscapePress)
      document.removeEventListener('fullscreenchange', handleDocumentFullscreenChange)
    }
  }, [ isFullscreen, toggleFullscreen ])

  if (!Hls.isSupported()) {
    console.error('This browser is not supporting MediaSource Extensions')
    return null
  }

  return (
    <>
      {
        isLoading && (
          <img
            className="absolute"
            src={loaderImage}
            width="16"
            style={{ top: 'calc(50% - 8px)', left: 'calc(50% - 8px)' }}
          />
        )
      }
      <video
        className={cx(s.video, 'object-cover')}
        ref={videoRef}
        poster={preview}
        autoPlay={autoplay}
        controls={false}
        onPlay={onPlay}
        muted
      />
      <div
        className={cx('flex opacity-72 justify-between', isFullscreen ? 'mx-24px mt-12px' : 'mx-16px mt-8px')}
      >
        {
          isFullscreen ? (
            <Text
              className="flex-1"
              message={title}
              size="t16-20"
              color="amelie"
            />
          ) : (
            <div className="flex-1"/>
          )
        }
        <div className="flex">
          <Href
            className={cx('pointer', { 'ml-20px': isFullscreen })}
            onClick={toggleFullscreen}
          >
            <Icon
              name={isFullscreen ? 'menu/exit-fullscreen_16' : 'menu/fullscreen_16'}
              color="amelie"
            />
          </Href>
        </div>
      </div>
    </>
  )
}

export default StreamPlayer