// Source: https://www.figma.com/file/38TL5rghJaAN5awvOdr9nh/РИР%3A-Иконки?node-id=0%3A1
import React, { CSSProperties, forwardRef } from 'react'
import cx from 'classnames'
import config from 'config'

import { colors } from 'helpers/constants/colors'

import { InlineSvg } from 'components/dataDisplay'

import { loadSvg } from '../InlineSvg/util'

import s from './Icon.scss'

import type { IconName } from './images/icons'

import images from '!!icons-loader!./images'


export const icons = images

export type { IconName } from './images/icons'
export type IconColor = typeof colors[number]

export type IconProps = {
  className?: string
  style?: CSSProperties
  name: IconName
  preload?: IconName
  color?: IconColor | 'inherit'
  ariaDescribedby?: string
  rotate?: 180

  width?: number
  height?: number
}

export const getStorageUrl = (iconName) => {
  if (iconName) {
    const size = iconName.replace(/.+_/, '')
    const prefix = `${config.storage}/common/icons/`

    if (size) {
      return `${prefix}${size}/${iconName}.svg`
    }

    return `${prefix}${size}/${iconName}.svg`
  }

  return ''
}

export const loadIcon = (name) => {
  // if (icons[name]) {
  //   loadSvg(icons[name].src)
  // }
  if (name) {
    return loadSvg(getStorageUrl(name))
      .then(({ body, svgAttributes }) => {
        const _svgAttributes = { class: 'inline-block', role: 'img', ...svgAttributes }

        const attributes = Object.keys(_svgAttributes)
          .map((attribute) => `${attribute}="${_svgAttributes[attribute]}"`)
          .join(' ')

        return `<svg ${attributes}>${body}</svg>`
      })
  }
}

const getStorageData = ({ name, preload }) => ({
  name: getStorageUrl(name),
  preload: getStorageUrl(preload),
})

const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { className, name, preload, color = 'inherit', rotate, ariaDescribedby, ...rest } = props

  if (!icons[name]) {
    // mock for tests
    if (__TEST__) {
      return (
        <svg width={1} height={1} viewBox="0 0 1 1" />
      )
    }

    console.error(`There is no icon with name "${name}"`)

    return null
  }

  const storageData = getStorageData({ name, preload })

  const { src, aspect, width, height } = icons[name]
  const { src: preloadSrc } = icons[preload] || {}

  const rootClassName = cx(className, s[`rotate-${rotate}`], {
    [`color-${color}`]: color !== 'inherit',
  })

  return (
    <InlineSvg
      ref={ref}
      className={rootClassName}
      src={storageData.name || src}
      width={width}
      height={height}
      aspect={aspect}
      preload={storageData.preload || preloadSrc}
      ariaDescribedby={ariaDescribedby}
      {...rest}
    />
  )
})

Icon.displayName = 'Icon'


export default Icon
