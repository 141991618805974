import React from 'react'
import cx from 'classnames'

import { DropdownMenuProps } from 'components/navigation'
import { Text, Icon, TextSize, IconName } from 'components/dataDisplay'

import { EditGroup } from '..'
import { AvatarSize } from '../../Avatar'


type AvatarContentProps = {
  className?: string
  image: string
  size: AvatarSize
  style: 'dark' | 'light'
  editMenuItems: DropdownMenuProps['items']
  withFullscreen: boolean
  userName: string
  editable: boolean
  isAvatarHovered: boolean
  handleEditClick: (event: any) => void
}

const sizeMapping = [
  {
    size: 24,
    icon: 'main/avatar_16',
    text: 's13-b',
  },
  {
    size: 32,
    icon: 'main/avatar_16',
    text: 'h16-20',
  },
  {
    size: 40,
    icon: 'main/avatar_16',
    text: 'h20-24',
  },
  {
    size: 56,
    icon: 'main/avatar_20',
    text: 'h28',
  },
  {
    size: 72,
    icon: 'main/avatar_20',
    text: 'h40',
  },
  {
    size: 104,
    icon: 'main/avatar_32',
    text: 'h56',
  },
]

const getLetter = (str: string) => str ? str[0].toUpperCase() : ''

const AvatarContent: React.FC<AvatarContentProps> = (props) => {
  const { className, image, isAvatarHovered, editable, size, style, editMenuItems, withFullscreen,
    userName, handleEditClick } = props

  return (
    image ? ((isAvatarHovered && editable) && (
      <EditGroup
        image={image}
        size={size}
        style={style}
        editMenuItems={editMenuItems}
        withFullscreen={withFullscreen}
        handleEditClick={handleEditClick}
      />
    )) : userName ? (
      <Text
        className={cx({ 'opacity-48': style === 'light' })}
        message={getLetter(userName)}
        color={style === 'dark' ? 'amelie' : 'rocky'}
        size={sizeMapping.find((sizeObj) => sizeObj.size === size).text as TextSize}
      />
    ) : (
      <Icon
        className={cx({ 'opacity-48': style === 'light' })}
        name={sizeMapping.find((sizeObj) => sizeObj.size === size).icon as IconName}
        color={style === 'dark' ? 'amelie' : 'rocky'}
      />
    )
  )
}

export default AvatarContent