import React from 'react'
import cx from 'classnames'

import { ButtonBase } from 'components/inputs'
import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'

import Icon, { IconName } from '../Icon/Icon'
import IconWithText from '../IconWithText/IconWithText'

import s from './Attachment.scss'


type AttachmentProps = {
  className?: string
  title: string
  icon: IconName
  text?: string
  onDeleteClick: () => void
  to?: string
  disabled?: boolean
}

const Attachment: React.FC<AttachmentProps> = (props) => {
  const { className, title, text, icon, onDeleteClick, to, disabled } = props

  return (
    <>
      <div className={cx(className, 'flex justify-between')}>
        <Href className="flex overflow-hidden" toTab={to}>
          <IconWithText
            className={cx('overflow-hidden', { 'opacity-48': disabled })}
            iconName={icon}
            message={title}
            textProps={{
              className: 'ml-10px opacity-72 overflow-ellipsis whitespace-nowrap',
              size: 't16-20',
            }}
            textColor="titanic"
          />
        </Href>
        <ButtonBase className={cx({ 'cursor-default': disabled })} onClick={!disabled ? onDeleteClick : () => {}}>
          <Icon
            className={cx({ 'opacity-48': disabled })}
            name="main/delete_16"
            color="fargo"
          />
        </ButtonBase>
      </div>
      {
        text && text !== '' && (
          <Text
            className={cx(s.text, 'opacity-48')}
            message={text}
            size="s13-r"
            color="titanic"
          />
        )
      }
    </>
  )
}

export default Attachment