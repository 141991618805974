import cx from 'classnames'
import { getUrl } from 'hooks/query/util'
import { getImage } from 'models/mapLayer'
import React, { useEffect, useState } from 'react'

import { Href } from 'components/navigation'
import { ButtonBase } from 'components/inputs'
import { Image, Text } from 'components/dataDisplay'

import Icon from '../Icon/Icon'

import s from './FilePreviewAttachment.scss'


type FilePreviewAttachmentProps = {
  className?: string
  url: string
  title: string
  disabled?: boolean
  isImage?: boolean
  withoutHref?: boolean
  onDeleteClick?: () => void
}

const FilePreviewAttachment: React.FC<FilePreviewAttachmentProps> = (props) => {
  const { className, url, title, withoutHref, onDeleteClick, disabled, isImage } = props

  const [ fileSize, setFileSize ] = useState('')

  useEffect(() => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const blob = xhr.response
      console.log(blob, blob.size)
      setFileSize(`${Math.round(Number(blob.size) / 1024)} КБайт`)
    }
    xhr.send()
  }, [ url ])

  return (
    <div className={cx(className, s.container, 'flex radius-8 pr-16px')}>
      {
        isImage ? (
          <Image
            className="radius-8"
            src={getImage(url)}
            width="76"
            height="48"
          />
        ) : (
          <div className={cx(s.bgArrival, 'flex justify-center radius-8')} style={{ width: '76px', height: '48px' }}>
            <Icon
              className="self-center"
              name="file/file_20"
              color="rocky"
            />
          </div>
        )
      }
      <div
        className={cx('flex-1 ml-6px mt-6px', {
          'overflow-ellipsis webkit-box': title.length > 60, // TODO rework this, check for mobile
        })}
      >
        <Href toTab={withoutHref ? null : getUrl(url)}>
          <Text
            message={title}
            size="c16"
            color="titanic"
          />
        </Href>
        <Text
          message={fileSize}
          size="s13-r"
          color="rocky"
        />
      </div>
      {
        typeof onDeleteClick === 'function' && (
          <ButtonBase
            className={cx({ 'cursor-default': disabled })}
            onClick={disabled ? null : onDeleteClick}
          >
            <Icon
              className={cx({ 'opacity-48': disabled })}
              name="main/delete_16"
              color="fargo"
            />
          </ButtonBase>
        )
      }
    </div>
  )
}


export default FilePreviewAttachment
