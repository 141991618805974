import React from 'react'
import cx from 'classnames'
import { getTransportTypeInfo } from 'models/publicTransport'

import { Text } from 'components/dataDisplay'


type RouteIconMiniProps = {
  className?: string
  title: string
  type: PublicTransport.TransportTypeTitle
}

const RouteIconMini: React.FC<RouteIconMiniProps> = (props) => {
  const { className, title, type } = props

  const color = getTransportTypeInfo(type).color

  return (
    <div className={cx(className, `bg-${color}`, 'py-4px px-8px radius-8')}>
      <Text
        message={title}
        size="s13-r"
        color="amelie"
      />
    </div>
  )
}

export default RouteIconMini