import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import { getImage } from 'models/mapLayer'

import { Href } from 'components/navigation'
import { Image, Text } from 'components/dataDisplay'

import Icon, { IconColor, IconName } from '../Icon/Icon'

import s from './FileAttachment.scss'


const prefix = __DEV__ ? '/proxy' : ''

type FileAttachmentProps = {
  className?: string
  text?: string
  file?: File // we should input in props either File object or fileUrl
  fileUrl?: string
  icon: IconName
  iconColor?: IconColor
  to?: string
  toTab?: string
  withSize?: boolean
  withResize?: boolean
  onClick?: () => void
  disabled?: boolean
}

const FileAttachment: React.FC<FileAttachmentProps> = (props) => {
  const {
    className, file, fileUrl, icon, iconColor = 'rocky',
    withSize = true, text, onClick, disabled, to, toTab, withResize = true,
  } = props

  const isNoFile = !file && !fileUrl

  const [ fileSize, setFileSize ] = useState('')
  const [ fileName, setFileName ] = useState('')
  const [ isImage, setIsImage ] = useState<boolean>(false)

  useEffect(() => {
    if (fileUrl) {
      if (withSize) {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', prefix + fileUrl, true)
        xhr.responseType = 'blob'
        xhr.onload = (res) => {
          const blob = xhr.response as Blob

          const isUrlAbsolute = /^https?:\/\//i.test(fileUrl)
          const args = [ prefix + fileUrl, !isUrlAbsolute && window.location.origin ].filter(Boolean) as [string, string?]
          const name = (new URL(...args)).pathname.split('/').pop()
          const size = `${Math.round(Number(blob.size) / 1024)} КБайт`
          const isImage = /image/.test(xhr.response.type)

          setFileName(name)
          setFileSize(size)
          setIsImage(isImage)
        }
        xhr.send()
      }
      else {
        setFileName(fileUrl.replace(/.*\//, ''))
        setIsImage(/(jpe?g|png|svg)$/.test(fileUrl))
      }
    }
  }, [ withSize, fileUrl, fileName ])

  useEffect(() => {
    if (file) {
      const { name, size } = file
      setFileName(name)
      setFileSize(`${Math.round(Number(size) / 1024)} КБайт`)
    }
  }, [ file ])

  useEffect(() => {
    if (isNoFile) {
      setFileName('Файл не загружен')
    }
  }, [ isNoFile ])

  return (
    <div className={cx(className, s.container, 'flex radius-8 pr-16px items-center', { 'opacity-48': disabled || isNoFile })}>
      {
        isImage ? (
          <Image
            className="radius-8"
            src={getImage(fileUrl, withResize)}
            width="92"
            height="54"
          />
        ) : (
          <div
            className={cx(s.file, 'radius-8 px-36px py-16px')}
          >
            <Icon
              name="file/file_20"
              color="rocky"
            />
          </div>
        )
      }
      <div
        className={cx('flex-1 ml-12px', {
          'overflow-ellipsis webkit-box': fileName?.length > 60,
        })}
      >
        <Text
          message={fileName}
          size="c16"
          color="titanic"
        />
        <Text
          message={fileSize}
          size="s13-r"
          color="rocky"
        />
      </div>
      {
        !disabled && !isNoFile && (
          <Href
            className={cx('flex')}
            onClick={onClick}
            to={to}
            toTab={toTab}
          >
            <Icon
              className="pointer ml-8px"
              name={icon}
              color={iconColor}
            />
          </Href>
        )
      }
    </div>
  )
}


export default FileAttachment
