import React from 'react'


type StyleProps = {
  rules: {
    selectors: string[]
    bgImage?: string
    bgImage2x?: string
    grid?: number[]
  }[]
}

const retinaCondition = `@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)`

const Style: React.FC<StyleProps> = (props) => {
  const { rules } = props

  const styles = rules.reduce((result, { selectors, bgImage, bgImage2x, grid }) => {
    const selector = selectors.map((selector) => /\./.test(selector) ? selector : `.${selector}`).join(',')

    if (grid) {
      result.common.push(`${selector} { grid-template-columns: ${grid.join('px ') + 'px'} }`)
    }
    if (bgImage) {
      result.common.push(`${selector} { background-image: url(${bgImage}); }`)
    }

    if (bgImage2x) {
      result.retina.push(`${selector} { background-image: url(${bgImage2x}); }`)
    }

    return result
  }, {
    common: [],
    retina: [],
  })

  return (
    <style>
      {styles.common.join('')}
      {Boolean(styles.retina?.length) && `${retinaCondition} {${styles.retina.join('')}}`}
    </style>
  )
}


export default React.memo(Style)
