import React, { Fragment } from 'react'
import cx from 'classnames'
import { getGlobalHtmlAttrs, GlobalHtmlAttributes } from 'helpers/getters'

import { Link } from 'components/inputs'

import { colors } from 'helpers/constants/colors'

import replaceReactComponents from './util/replaceReactComponents'


export const sizesMap = {
  headers: [ 'h72', 'h56', 'h40', 'h32', 'h28', 'h24', 'h20-28', 'h20-24', 'h16-24', 'h16-20' ],
  controls: [ 'c20', 'c16', 'c13', 'c10' ],
  text: [ 't20-28', 't20-24', 't16-24', 't16-20' ],
  service: [ 's13-b', 's13-r', 's10' ],
} as const

export const sizes = [
  ...sizesMap.headers,
  ...sizesMap.controls,
  ...sizesMap.text,
  ...sizesMap.service,
] as const

export const aligns = [ 'left', 'center', 'right' ] as const

export type TextSize = typeof sizes[number]
export type TextColor = typeof colors[number]
export type TextAlign = typeof aligns[number]

export type TextBaseProps = {
  children?: React.ReactNode,
  id?: string
  className?: string
  message?: string
  tag?: string
  size: TextSize
  color?: TextColor | 'inherit'
  align?: TextAlign
  html?: boolean
  role?: string
  tabIndex?: number
  dataTestId?: string
  wrapWord?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const getMessageWithLink = (message) => {
  const formattedMessageArr: any[] = replaceReactComponents(message, {
    Link,
  })

  return formattedMessageArr.filter((messageItem) => messageItem).map((messageItem, index) => {
    if (typeof messageItem === 'object') {
      return <Fragment key={index}>{messageItem}</Fragment>
    }

    return <span key={index} dangerouslySetInnerHTML={{ __html: messageItem }} />
  })
}

const Text = React.forwardRef<HTMLElement, TextBaseProps & GlobalHtmlAttributes>((props, ref) => {
  let {
    children, id, className, tag = 'div', message, wrapWord,
    size, color = 'inherit', align, html, role, dataTestId, tabIndex,
    onClick, ...otherProps
  } = props

  if (onClick && tag !== 'button') {
    console.error('You can\'t use "onClick" without passing tag === "button". Create components ADA friendly!')
  }

  const rootClassName = cx(className, {
    [`text-${size}`]: size,
    [`color-${color}`]: color !== 'inherit',
    'wrap-word': wrapWord,
  })

  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  if (message) {
    children = html ? getMessageWithLink(message) : message
  }

  return React.createElement(tag, {
    ...htmlAttrs,
    ref,
    id,
    className: rootClassName,
    role,
    tabIndex,
    'data-testid': dataTestId,
    onClick,
  }, children)
})


export default Text
