import React from 'react'
import cx from 'classnames'

import { getBase64Image } from 'helpers'

import { Icon } from 'components/dataDisplay'
import { DropdownMenu, DropdownMenuProps } from 'components/navigation'
import { openFullScreenModal } from 'compositions/modals'

import { AvatarSize } from '../../Avatar'

import s from '../../Avatar.scss'


type EditGroupProps = {
  className?: string
  image?: string
  size: AvatarSize
  style?: 'dark' | 'light'
  editMenuItems?: DropdownMenuProps['items']
  withFullscreen?: boolean
  handleEditClick: (event: any) => void
}

const EditGroup: React.FC<EditGroupProps> = (props) => {
  const { className, size, style, withFullscreen, image, editMenuItems, handleEditClick } = props

  return (
    (size === 72) ? (
      <div
        className={cx(s.hoverOpacity, 'opacity-72 pointer mb-4px')}
        onClick={handleEditClick}
      >
        <Icon
          className={cx({ 'opacity-48': style === 'light' })}
          name='main/edit-picture_16'
          color={style === 'dark' ? 'amelie' : 'rocky'}
        />
      </div>
    ) : (
      (size === 104) && (
        editMenuItems ? (
          <div
            className={cx('flex flex-col items-center pointer')}
          >
            {
              withFullscreen && (
                <div className="flex" onClick={async () => openFullScreenModal({
                  images: /base64/.test(image) ? [ await getBase64Image(image) ] : [ image.replaceAll('&h=214', '') ],
                  initialSlide: 0,
                  isWithLegend: false,
                  // isImageTrueSize: true, // TODO this prop is not working, fix
                })}>
                  <Icon
                    className={cx(s.hoverOpacity, 'flex mb-12px opacity-72')}
                    name="main/search_16"
                    color={style === 'dark' ? 'amelie' : 'rocky'}
                  />
                </div>
              )
            }
            <DropdownMenu items={editMenuItems}>
              <Icon
                className={cx(s.hoverOpacity, 'flex mb-16px opacity-72')}
                name="main/edit-picture_16"
                color={style === 'dark' ? 'amelie' : 'rocky'}
              />
            </DropdownMenu>
          </div>
        ) : (
          <div
            className={cx(s.hoverOpacity, 'flex flex-col items-center opacity-72 pointer')}
          >
            <div className="" onClick={handleEditClick}>
              <Icon
                className={cx(s.icon, 'flex mb-16px')}
                name="main/edit-picture_16"
                color={style === 'dark' ? 'amelie' : 'rocky'}
              />
            </div>
          </div>
        )
      )
    )
  )
}

export default EditGroup