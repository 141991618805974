import React from 'react'
import cx from 'classnames'

import s from './CheckboxIcon.scss'


type CheckboxIconProps = {
  className?: string
  active?: boolean
  disabled?: boolean
  isHover?: boolean
}

// TODO move to images
const Checkmark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
    <g filter="url(#filter0_d)">
      <path fill="#fff" fillRule="evenodd" d="M5.9 5.9c.2.1.6.2.9-.1L12.9.3c.6-.7 1.4 0 1 .5L7.3 9.6a1 1 0 01-1.5 0L2.1 4.8c-.3-.4.3-.9.7-.6l3 1.7z" clipRule="evenodd"/>
    </g>
    <defs>
      <filter id="filter0_d" width="16" height="16" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix values="0 0 0 0 0.0745098 0 0 0 0 0.223529 0 0 0 0 0.52549 0 0 0 0.32 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
)

const CheckboxIcon: React.FC<CheckboxIconProps> = (props) => {
  const { className, active, disabled, isHover } = props

  const rootClassName = cx(s.icon, 'radius-2', className, {
    [s.active]: active,
    [s.disabled]: disabled,
    [s.hover]: isHover && !disabled,
  })

  return (
    <div className={rootClassName}>
      {
        Boolean(active) && (
          <Checkmark />
        )
      }
    </div>
  )
}


export default CheckboxIcon
