import React, { ReactNode, useRef } from 'react'
import cx from 'classnames'
import { useEntry } from 'intersection-observer'

import { Image, ImageProps } from 'components/dataDisplay'

import loaderImage from '../Image/images/spinner.svg'


type LazyImageProps = ImageProps & {
  children?: ReactNode
  offset?: string
}

const LazyImage: React.FC<LazyImageProps> = (props) => {
  const { children, className, offset, ...rest } = props

  const { ref, isVisible } = useEntry({ once: true })

  return (
    <div
      className={cx(className, 'relative')}
      style={{
        width: rest.width ? `${rest.width}px` : undefined,
        height: rest.height ? `${rest.height}px` : undefined,
      }}
    >
      {
        isVisible ? (
          <Image
            {...rest}
          />
        ) : (
          <>
            <div
              ref={ref}
              className="absolute"
              style={{
                width: '100px',
                height: '100px',
                top: offset,
              }}
            />
            <div
              className="absolute w-full h-full opacity-24 z-1"
              style={{
                backgroundImage: `url(${loaderImage})`,
                backgroundSize: '50px',
                backgroundPosition: 'center',
              }}
            />
            {children}
          </>
        )
      }
    </div>
  )
}


export default React.memo(LazyImage)
