import React, { useMemo } from 'react'
import config from 'config'
import cx from 'classnames'
import { useDevice } from 'device'
import { useCityConfig } from 'models/city'

import { Text } from 'components/dataDisplay'

import s from './Logo.scss'


type LogoProps = {
  className?: string
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  const { isMobile } = useDevice()
  const { cityConfig, platformTitle } = useCityConfig()

  // https://storage.rosatom.city/platform-prod/750/100/cities/sub12/logo.png
  const prefix = __DEV__ ? '/proxy' : ''

  const logoImage = useMemo(() => cityConfig?.logoSvg
    ? prefix + cityConfig?.logoSvg
    : config.isMultipleDomains
      ? `${config.storage.replace(`/cities`, '')}/-/80/cities/${config.subDomain}/logo.png`
      : `${config.storage}/common/logo.svg`, [ cityConfig, prefix ])

  return (
    <div className={cx(className, 'inline-flex items-center')}>
      <img
        className={s.logo}
        src={logoImage}
        alt="logo"
        height={isMobile ? 32 : 40}
      />
      <Text
        className="ml-8px whitespace-nowrap"
        message={platformTitle}
        size={isMobile ? 'c10' : 'c13'}
        color="interstellar"
        html
      />
    </div>
  )
}


export default Logo
