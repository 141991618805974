import React, { HTMLProps, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'

import Glider from 'glider-js'
import { Icon, Text } from 'components/dataDisplay'

import s from './Carousel.scss'
import { Href } from 'components/navigation'
import { openFullScreenModal } from 'compositions/modals/FullScreenModal/FullScreenModal'


// More info about the options https://nickpiscitelli.github.io/Glider.js/#settings
export type CarouselOptions = {
  slidesToShow?: number | 'auto'
  slidesToScroll?: number | 'auto' // if auto it will match slidesToShow
  itemWidth?: number // ignored unless slidesToShow is set to auto
  exactWidth?: boolean // ignored unless slidesToShow is set to auto
  scrollLock?: boolean // if true, it will scroll to the nearest slide after any scroll interactions
  scrollLockDelay?: number
  resizeLock?: boolean // if true, it will lock to the nearest slide on resizing of the window
  rewind?: boolean // if true, it will scroll to the beginning/end when its respective endpoint is reached
  draggable?: boolean // if true, it can be scrolled by click with the mouse
  duration?: number // default: 0.5 An aggravator used to control animation speed. Higher is slower!
}

export type CarouselEvents = {
  // add events if needed https://nickpiscitelli.github.io/Glider.js/#events
  // onLoaded?: (event: CustomEvent) => void // when carousel component is loaded and initialized
  // onSlideVisible?: (event: CustomEvent<{ slide: number }>) => void
}

export type CarouselProps = CarouselOptions & CarouselEvents & {
  children: React.ReactNode
  // ATTN we use only one className for container. If you need to override some properties use glider-* global classes
  className?: string
  withArrows?: boolean
  fullScreenImages?: string[]
  prevIcon?: React.ReactNode
  nextIcon?: React.ReactNode
  withDots?: boolean
  withLegend?: boolean
  autoplay?: boolean
  autoplaySpeed?: number
  initialSlide?: number
  style?: React.CSSProperties
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const {
    children,
    className,
    withArrows,
    fullScreenImages,
    prevIcon,
    nextIcon,
    withDots,
    withLegend,
    // options
    slidesToShow,
    slidesToScroll = 1,
    itemWidth,
    exactWidth,
    scrollLock = true,
    scrollLockDelay = 150,
    resizeLock = true,
    rewind = false,
    draggable,
    duration = true,
    // autoplay
    autoplay,
    autoplaySpeed = 2500,
    // events
    // onLoaded,
    // onSlideVisible,
    initialSlide = 0,
    style
  } = props

  const [ isLoaded, setLoaded ] = useState(false)
  const [ legend, setLegend ] = useState(withLegend ? `${initialSlide + 1} из ${React.Children.count(children)}` : '')

  const onLoad = () => setLoaded(true)
  const carouselRef = useRef<HTMLDivElement>(null)
  const slidesRef = useRef<HTMLDivElement>(null)
  const prevArrowRef = useRef<HTMLButtonElement>(null)
  const nextArrowRef = useRef<HTMLButtonElement>(null)
  const dotsRef = useRef<HTMLDivElement>(null)

  const gliderRef = useRef<any>(null)

  // Library initialization
  useEffect(() => {
    if (!slidesRef.current) {
      return
    }

    gliderRef.current = new Glider(slidesRef.current, {
      skipTrack: true,
    })

    return () => {
      gliderRef.current.destroy()
    }
  }, [])

  // Options update when props are updated
  useEffect(() => {
    if (!gliderRef.current) {
      return
    }

    gliderRef.current.setOption({
      // default options
      slidesToShow,
      slidesToScroll,
      itemWidth,
      exactWidth,
      scrollLock,
      scrollLockDelay,
      resizeLock,
      rewind,
      draggable,
      duration,
      //
      dots: withDots ? dotsRef.current : null,
      arrows: withArrows ? {
        prev: prevArrowRef.current,
        next: nextArrowRef.current,
      } : null,
    }, true)

    gliderRef.current.refresh(true)

    if (initialSlide > 0) {
      gliderRef.current.scrollItem(initialSlide)
    }

    if (withLegend) {
      const handleSetLegend = ({ detail: { slide } }) => setLegend(`${slide + 1} из ${gliderRef.current.slides.length}`)
      gliderRef.current.ele.addEventListener('glider-slide-visible', handleSetLegend)

      return () => {
        gliderRef.current.ele.removeEventListener('glider-slide-visible', handleSetLegend)
      }
    }
  }, [
    withDots, withArrows, children, slidesToShow, slidesToScroll, itemWidth, exactWidth,
    scrollLock, scrollLockDelay, resizeLock, rewind, draggable, duration, initialSlide,
  ])

  // Autoplay
  useEffect(() => {
    const carouselElement = carouselRef.current

    if (!autoplay || !carouselElement) {
      return
    }

    let autoplayInterval = null

    const start = () => {
      if (autoplayInterval === null) {
        autoplayInterval = setInterval(() => {
          if (gliderRef.current) {
            gliderRef.current.scrollItem('next')
          }
        }, autoplaySpeed)
      }
    }

    const stop = () => {
      if (autoplayInterval !== null) {
        clearInterval(autoplayInterval)
        autoplayInterval = null
      }
    }

    carouselElement.addEventListener('mouseover', stop)
    carouselElement.addEventListener('touchstart', stop)
    carouselElement.addEventListener('mouseout', start)
    carouselElement.addEventListener('touchcancel', start)
    carouselElement.addEventListener('touchend', start)
    start()

    return () => {
      stop()
      carouselElement.removeEventListener('mouseover', stop)
      carouselElement.removeEventListener('touchstart', stop)
      carouselElement.removeEventListener('mouseout', start)
      carouselElement.removeEventListener('touchcancel', start)
      carouselElement.removeEventListener('touchend', start)
    }
  }, [ autoplay, autoplaySpeed ])

  const {
    prevLabel,
    nextLabel,
  } = useMemo(() => ({
    prevLabel: 'Previous',
    nextLabel: 'Next',
  }), [])

  const { trackStyle, slideStyle } = useMemo(() => {
    if (!itemWidth) {
      return {}
    }

    // this styles are needed to support the same appearance before carousel is initialized
    // the will be replaced by glider-js
    return {
      trackStyle: {
        width: `${itemWidth * React.Children.count(children)}px`,
      },
      slideStyle: {
        width: `${itemWidth}px`,
      },
    }
  }, [ itemWidth, children ])

  const carouselClassName = cx(s.carousel, className)

  const openFullScreen = () => openFullScreenModal({
    images: fullScreenImages,
    initialSlide: gliderRef.current.slide,
  })

  return (
    <div ref={carouselRef} className={carouselClassName} style={style}>
      <div ref={slidesRef} className="glider">
        <div className="glider-track" style={trackStyle}>
          {
            React.Children.map(children, (child) => (
              <div className="glider-slide" style={slideStyle}>
                {React.cloneElement(child, { onLoad })}
              </div>
            ))
          }
        </div>
      </div>
      {
        Boolean(fullScreenImages?.length && isLoaded) && (
          <Href
            className={cx(s.fullScreen, 'pointer w-full h-full flex items-center justify-center')}
            onClick={openFullScreen}
          >
            <Icon name="main/search_56" color="white" />
          </Href>
        )
      }
      {
        Boolean(withArrows) && (
          <>
            <button ref={prevArrowRef} className="glider-prev" aria-label={prevLabel}>
              {prevIcon || <Icon className="ml-8px" name="arrow/arrow-left_32" color="inherit" />}
            </button>
            <button ref={nextArrowRef} className="glider-next" aria-label={nextLabel}>
              {nextIcon || <Icon className="mr-8px" name="arrow/arrow-right_32" color="inherit" />}
            </button>
          </>
        )
      }
      {
        Boolean(withDots) && (
          <div ref={dotsRef} className="glider-dots" role="tablist" />
        )
      }
      {
        Boolean(withLegend && isLoaded) && (
          <Text
            className={cx(s.text, 'opacity-72 bg-rush p-4px radius-8 mb-8px')}
            message={legend}
            size="c13"
            color="titanic"
          />
        )
      }
    </div>
  )
}


export default Carousel
