import React from 'react'

import { Text } from 'components/dataDisplay'

import s from './MP4Player.scss'


type MP4PlayerProps = {
  url?: string
  preview?: string
  autoplay?: boolean
  controls?: boolean
  onPlay?: () => void
}

const MP4Player: React.FC<MP4PlayerProps> = (props) => {
  const { url, preview, autoplay, controls, onPlay } = props

  return (
    <video
      className={s.video}
      poster={preview}
      autoPlay={autoplay}
      controls={controls}
      onPlay={onPlay}
    >
      <source src={url} type="video/mp4" />
      <Text message="Браузер не поддерживается" size="t16-20" />
    </video>
  )
}


export default MP4Player
