import _1659fz_16 from "./16/59FZ_16.svg"
import _16Android_16 from "./16/Android_16.svg"
import _16Apple_16 from "./16/Apple_16.svg"
import _16Facebook_16 from "./16/Facebook_16.svg"
import _16Google_16 from "./16/Google_16.svg"
import _16Gosuslugi_16 from "./16/Gosuslugi_16.svg"
import _16Instagram_16 from "./16/Instagram_16.svg"
import _16Mailru_16 from "./16/Mailru_16.svg"
import _16Medialogia_16 from "./16/Medialogia_16.svg"
import _16Odnoklassniki_16 from "./16/Odnoklassniki_16.svg"
import _16Telegram_16 from "./16/Telegram_16.svg"
import _16Twitter_16 from "./16/Twitter_16.svg"
import _16Viber_16 from "./16/Viber_16.svg"
import _16Vkontakte_16 from "./16/Vkontakte_16.svg"
import _16Whatsapp_16 from "./16/WhatsApp_16.svg"
import _16Yandex_16 from "./16/Yandex_16.svg"
import _20Facebook_20 from "./20/Facebook_20.svg"
import _20Gosuslugi_20 from "./20/Gosuslugi_20.svg"
import _20Instagram_20 from "./20/Instagram_20.svg"
import _20Mailru_20 from "./20/Mailru_20.svg"
import _20Odnoklassniki_20 from "./20/Odnoklassniki_20.svg"
import _20Twitter_20 from "./20/Twitter_20.svg"
import _20Vkontakte_20 from "./20/Vkontakte_20.svg"
import _20Yandex_20 from "./20/Yandex_20.svg"
import _32Apple_32 from "./32/Apple_32.svg"
import _32Facebook_32 from "./32/Facebook_32.svg"
import _32Google_32 from "./32/Google_32.svg"
import _32Gosuslugi_32 from "./32/Gosuslugi_32.svg"
import _32Instagram_32 from "./32/Instagram_32.svg"
import _32Mailru_32 from "./32/Mailru_32.svg"
import _32Odnoklassniki_32 from "./32/Odnoklassniki_32.svg"
import _32Telegram_32 from "./32/Telegram_32.svg"
import _32Twitter_32 from "./32/Twitter_32.svg"
import _32Viber_32 from "./32/Viber_32.svg"
import _32Vkontakte_32 from "./32/Vkontakte_32.svg"
import _32Whatsapp_32 from "./32/WhatsApp_32.svg"
import _32Yandex_32 from "./32/Yandex_32.svg"
import _40Apple_40 from "./40/Apple_40.svg"
import _40Facebook_40 from "./40/Facebook_40.svg"
import _40Google_40 from "./40/Google_40.svg"
import _40Gosuslugi_40 from "./40/Gosuslugi_40.svg"
import _40Instagram_40 from "./40/Instagram_40.svg"
import _40Mailru_40 from "./40/Mailru_40.svg"
import _40Odnoklassniki_40 from "./40/Odnoklassniki_40.svg"
import _40Twitter_40 from "./40/Twitter_40.svg"
import _40Vkontakte_40 from "./40/Vkontakte_40.svg"
import _40Yandex_40 from "./40/Yandex_40.svg"
import _socialApple_16 from "./Social/Apple_16.svg"
import _socialApple_32 from "./Social/Apple_32.svg"
import _socialApple_40 from "./Social/Apple_40.svg"

export default { '16/59FZ_16': { src: _1659fz_16, width: 16, height: 16, aspect: 1 },
'16/Android_16': { src: _16Android_16, width: 16, height: 16, aspect: 1 },
'16/Apple_16': { src: _16Apple_16, width: 16, height: 17, aspect: 0.9411764705882353 },
'16/Facebook_16': { src: _16Facebook_16, width: 16, height: 16, aspect: 1 },
'16/Google_16': { src: _16Google_16, width: 16, height: 16, aspect: 1 },
'16/Gosuslugi_16': { src: _16Gosuslugi_16, width: 16, height: 16, aspect: 1 },
'16/Instagram_16': { src: _16Instagram_16, width: 16, height: 16, aspect: 1 },
'16/Mailru_16': { src: _16Mailru_16, width: 16, height: 16, aspect: 1 },
'16/Medialogia_16': { src: _16Medialogia_16, width: 16, height: 16, aspect: 1 },
'16/Odnoklassniki_16': { src: _16Odnoklassniki_16, width: 16, height: 16, aspect: 1 },
'16/Telegram_16': { src: _16Telegram_16, width: 16, height: 16, aspect: 1 },
'16/Twitter_16': { src: _16Twitter_16, width: 16, height: 16, aspect: 1 },
'16/Viber_16': { src: _16Viber_16, width: 16, height: 16, aspect: 1 },
'16/Vkontakte_16': { src: _16Vkontakte_16, width: 16, height: 16, aspect: 1 },
'16/WhatsApp_16': { src: _16Whatsapp_16, width: 16, height: 16, aspect: 1 },
'16/Yandex_16': { src: _16Yandex_16, width: 16, height: 16, aspect: 1 },
'20/Facebook_20': { src: _20Facebook_20, width: 20, height: 20, aspect: 1 },
'20/Gosuslugi_20': { src: _20Gosuslugi_20, width: 20, height: 20, aspect: 1 },
'20/Instagram_20': { src: _20Instagram_20, width: 20, height: 20, aspect: 1 },
'20/Mailru_20': { src: _20Mailru_20, width: 20, height: 20, aspect: 1 },
'20/Odnoklassniki_20': { src: _20Odnoklassniki_20, width: 20, height: 20, aspect: 1 },
'20/Twitter_20': { src: _20Twitter_20, width: 20, height: 20, aspect: 1 },
'20/Vkontakte_20': { src: _20Vkontakte_20, width: 20, height: 20, aspect: 1 },
'20/Yandex_20': { src: _20Yandex_20, width: 20, height: 20, aspect: 1 },
'32/Apple_32': { src: _32Apple_32, width: 32, height: 32, aspect: 1 },
'32/Facebook_32': { src: _32Facebook_32, width: 32, height: 32, aspect: 1 },
'32/Google_32': { src: _32Google_32, width: 32, height: 32, aspect: 1 },
'32/Gosuslugi_32': { src: _32Gosuslugi_32, width: 32, height: 32, aspect: 1 },
'32/Instagram_32': { src: _32Instagram_32, width: 32, height: 32, aspect: 1 },
'32/Mailru_32': { src: _32Mailru_32, width: 32, height: 32, aspect: 1 },
'32/Odnoklassniki_32': { src: _32Odnoklassniki_32, width: 32, height: 32, aspect: 1 },
'32/Telegram_32': { src: _32Telegram_32, width: 32, height: 32, aspect: 1 },
'32/Twitter_32': { src: _32Twitter_32, width: 32, height: 32, aspect: 1 },
'32/Viber_32': { src: _32Viber_32, width: 32, height: 32, aspect: 1 },
'32/Vkontakte_32': { src: _32Vkontakte_32, width: 32, height: 32, aspect: 1 },
'32/WhatsApp_32': { src: _32Whatsapp_32, width: 32, height: 32, aspect: 1 },
'32/Yandex_32': { src: _32Yandex_32, width: 32, height: 32, aspect: 1 },
'40/Apple_40': { src: _40Apple_40, width: 40, height: 40, aspect: 1 },
'40/Facebook_40': { src: _40Facebook_40, width: 40, height: 40, aspect: 1 },
'40/Google_40': { src: _40Google_40, width: 40, height: 40, aspect: 1 },
'40/Gosuslugi_40': { src: _40Gosuslugi_40, width: 40, height: 40, aspect: 1 },
'40/Instagram_40': { src: _40Instagram_40, width: 40, height: 40, aspect: 1 },
'40/Mailru_40': { src: _40Mailru_40, width: 40, height: 40, aspect: 1 },
'40/Odnoklassniki_40': { src: _40Odnoklassniki_40, width: 40, height: 40, aspect: 1 },
'40/Twitter_40': { src: _40Twitter_40, width: 40, height: 40, aspect: 1 },
'40/Vkontakte_40': { src: _40Vkontakte_40, width: 40, height: 40, aspect: 1 },
'40/Yandex_40': { src: _40Yandex_40, width: 40, height: 40, aspect: 1 },
'Social/Apple_16': { src: _socialApple_16, width: 16, height: 17, aspect: 0.9411764705882353 },
'Social/Apple_32': { src: _socialApple_32, width: 32, height: 32, aspect: 1 },
'Social/Apple_40': { src: _socialApple_40, width: 40, height: 40, aspect: 1 } }