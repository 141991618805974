import React from 'react'
import cx from 'classnames'
import { getTransportTypeInfo } from 'models/publicTransport'

import { Icon, Text } from '..'

import s from './RouteIcon.scss'


type RouteIconProps = {
  className?: string
  title?: string
  transportType: PublicTransport.TransportTypeTitle
  count?: number
  withInvalid?: boolean
  isActive?: boolean
  isEmpty?: boolean
  id?: string
  onClick?: (id: string) => void
}

const RouteIcon: React.FC<RouteIconProps> = (props) => {
  const { className, title, transportType, count, withInvalid, isActive = true, isEmpty, id, onClick } = props
  const { color, icon } = getTransportTypeInfo(transportType)
  return (
    <div
      className={cx(className, 'relative flex items-center no-shrink px-8px py-6px radius-8 mw-content', s[ `border-${color}` ], {
        [ s[ `bg-${color}` ] ]: isActive,
        'pointer': typeof onClick === 'function',
      })}
      style={{ width: 'max-content' }}
      id={id}
      onClick={() => onClick(id)}
    >
      <div className="flex flex-col">
        <Icon
          name={icon}
          color={!isActive ? color : 'amelie'}
        />
        {
          withInvalid && (
            <Icon
              className="mt-8px opacity-72"
              name="work/invalid_16"
              color={!isActive ? color : 'amelie'}
            />
          )
        }
      </div>
      {
        Boolean(title) && (
          <Text
            className="ml-6px"
            message={title}
            color={isEmpty ? 'fargo' : !isActive ? color : 'amelie'}
            size="c16"
          />
        )
      }
      {
        Boolean(typeof count === 'number') && (
          <div
            className="absolute radius-16 flex items-center justify-center bg-matrix"
            style={{ width: '16px', height: '16px', top: '-6px', right: '-6px' }}
          >
            <Text
              message={String(count)}
              size="s10"
              color="amelie"
            />
          </div>
        )
      }
    </div>
  )
}

export default RouteIcon