import React, { useMemo } from 'react'
import cx from 'classnames'
import config from 'config'

import { Href } from 'components/navigation'
import { InlineSvg } from 'components/dataDisplay'

import type { IconName } from './images/icons'

import images from '!!icons-loader!./images'


export type SocialIconProps = {
  className?: string
  name: IconName
  toTab?: string
  onClick?: () => void
}

const SocialIcon: React.FC<SocialIconProps> = (props) => {
  const { className, name, toTab, onClick } = props

  const image = images[name]?.src
  const cityName = config.cityName

  const link = useMemo(() => {
    const currentPageLink = window.location.href
    const title = `Умный ${cityName}`

    if (onClick) return ''
    if (toTab) return toTab

    if (/\/Vk/.test(name)) {
      return `https://vk.com/share.php?url=${currentPageLink}&title=${title}`
    }
    if (/\/Facebook/.test(name)) {
      return `https://www.facebook.com/sharer.php?src=sp&u==${currentPageLink}&title=${title}`
    }
    if (/\/WhatsApp/.test(name)) {
      return `https://api.whatsapp.com/send?text=${title}${currentPageLink}`
    }
    if (/\/Telegram/.test(name)) {
      return `https://t.me/share/url?url=${currentPageLink}&text=${title}`
    }
    if (/\/Viber/.test(name)) {
      return `viber://forward?&text=${title} ${currentPageLink}`
    }
    if (/\/Twitter/.test(name)) {
      return `https://twitter.com/intent/tweet?text=&url=${title} ${currentPageLink}`
    }

    console.error('No link for', name)

    return ''
  }, [ cityName, name, toTab, onClick ])

  return Boolean(image) && (
    <Href
      className={cx(className, (onClick || link) ? 'pointer' : '')}
      toTab={link}
      onClick={onClick}
    >
      <InlineSvg
        src={image}
      />
    </Href>
  )
}

export { images }

export default SocialIcon
