import cx from 'classnames'
import { getBase64Image } from 'helpers'
import React, { useState, useCallback } from 'react'

import { Icon } from 'components/dataDisplay'
import { DropdownMenuProps, Href } from 'components/navigation'
import { openFullScreenModal } from 'compositions/modals/FullScreenModal/FullScreenModal'

import { AvatarContent } from './components'

import s from './Avatar.scss'


export type AvatarSize = 24 | 32 | 40 | 56 | 72 | 104

export type AvatarProps = {
  className?: string
  userName?: string
  image?: string
  // originalImage?: string
  size: AvatarSize
  editable?: boolean
  onEditClick?: Function
  style?: 'dark' | 'light'
  shape?: 'round' | 'square'
  editMenuItems?: DropdownMenuProps['items']
  withFullscreen?: boolean
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const {
    className,
    userName,
    image,
    // originalImage,
    size,
    editable,
    style = 'dark',
    shape = 'round',
    onEditClick,
    editMenuItems,
    withFullscreen = false,
  } = props

  const rootClassName = cx(className, s.root, 'flex flex-none justify-center items-center', {
    [s[`size-${size}`]]: size,
    ['flex-col justify-end']: Boolean(image && editable && (size === 72 || size === 104)),
    [s.dark]: style === 'dark',
    [s.light]: style === 'light',
    [s.roundShape]: shape === 'round',
    'radius-8': shape === 'square',
    'pointer': editable,
  })

  const [ isAvatarHovered, setIsAvatarHovered ] = useState(false)

  const rootStyle = {
    backgroundImage: image
      // ? `${isAvatarHovered ? 'linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%), '
      //   : ''}url(${image})` : null,
      ? `url(${image})` : null,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }

  const handleEditClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    if (typeof onEditClick === 'function') {
      onEditClick()
    }
  }, [ onEditClick ])

  return (
    <Href
      onClick={editable ? handleEditClick : null}
      onMouseEnter={() => setIsAvatarHovered(true)}
      onMouseLeave={() => setIsAvatarHovered(false)}
    >
      <div
        className={cx(rootClassName, { [`${s.fullScreenHover} pointer`]: withFullscreen && !editable })}
        style={rootStyle}
        onClick={(withFullscreen && !editable) ? async () => openFullScreenModal({
          images: [ await getBase64Image(image.replaceAll('&h=214', '')) ],
          initialSlide: 0,
          isWithLegend: false,
          isImageTrueSize: true,
        }) : null}
      >
        {
          (withFullscreen && !editable) && (
            <Icon
              className={cx(s.hoverOpacity, 'flex opacity-0')}
              name="main/search_16"
              color={style === 'dark' ? 'amelie' : 'rocky'}
            />
          )
        }
        <AvatarContent
          image={image}
          size={size}
          style={style}
          userName={userName}
          editable={editable}
          editMenuItems={editMenuItems}
          withFullscreen={withFullscreen}
          isAvatarHovered={isAvatarHovered}
          handleEditClick={handleEditClick}
        />
      </div>
    </Href>
  )
}


export default Avatar
