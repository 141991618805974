import React from 'react'
import cx from 'classnames'

import s from './RadioIcon.scss'


type RadioIconProps = {
  className?: string
  active?: boolean
}

const RadioIcon: React.FC<RadioIconProps> = (props) => {
  const { className, active } = props

  const rootClassName = cx(s.icon, className, 'radius-100 pointer', {
    [s.active]: active,
  })

  return (
    <div className={rootClassName} data-active={active} />
  )
}


export default RadioIcon
