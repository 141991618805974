import React from 'react'
import { Helmet } from 'react-helmet-async'


type MetaProps = {
  title: string
  metaTitle?: string
  metaDescription?: string
  image?: string
  pathname?: string
  storage: string
}

const multiLineToOneLine = (src: string): string => (
  src.replace(/\n\s*|\s{2,}/mg, ' ').trim()
)

const Meta: React.FC<MetaProps> = (props) => {
  const { title, metaTitle, metaDescription, image, pathname, storage } = props

  const pageTitle = title
  const pageMetaTitle = metaTitle || pageTitle
  const pageMetaDescription = metaDescription ? multiLineToOneLine(metaDescription) : null

  return (
    <Helmet>
      <title>{pageTitle}</title>
      {
        Boolean(pageMetaTitle) && (
          [
            <meta name="title" content={pageMetaTitle} />,
            <meta property="og:title" content={pageMetaTitle} />,
          ]
        )
      }
      {
        Boolean(pageMetaDescription) && (
          [
            <meta name="description" content={pageMetaDescription} />,
            <meta property="og:description" content={pageMetaDescription} />,
          ]
        )
      }
      {
        Boolean(image) && (
          <meta property="og:image" content={image} />
        )
      }
      {
        Boolean(pathname) && (
          <link rel="canonical" href={`${pathname}`} />
        )
      }
      {__DEV__ && (<link rel="apple-touch-icon" sizes="180x180" href={`${storage}/common/favicon/apple-touch-icon.png`} />)}
      {__DEV__ && (<link rel="icon" type="image/png" sizes="32x32" href={`${storage}/common/favicon/favicon-32x32.png`} />)}
      {__DEV__ && (<link rel="icon" type="image/png" sizes="16x16" href={`${storage}/common/favicon/favicon-16x16.png`} />)}
      {__DEV__ && (<link rel="manifest" href={`${storage}/common/favicon/site.webmanifest`} />)}
      {__DEV__ && (<link rel="mask-icon" href={`${storage}/common/favicon/safari-pinned-tab.svg`} color="#5bbad5" />)}
      {__DEV__ && (<link rel="shortcut icon" href={`${storage}/common/favicon/favicon.ico`} />)}
      {__DEV__ && (<meta name="theme-color" content="#ffffff" />)}
      {__DEV__ && (<meta name="msapplication-TileColor" content="#da532c" />)}
      {__DEV__ && (<meta name="msapplication-config" content={`${storage}/common/favicon/browserconfig.xml`} />)}
      {__DEV__ && (<link rel="icon" type="image/svg+xml" href={`${storage}/common/favicon/favicon.svg`} />)}
      {__DEV__ && (<link rel="icon" type="image/png" href={`${storage}/common/favicon/favicon.png`} />)}
    </Helmet>
  )
}


export default React.memo(Meta)
